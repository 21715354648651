.busy {
  /* position: absolute;
  left: 50%;
  top: 20%;
  z-index: 1000;
  height: 31px;
  width: 31px; */
    position: absolute;
  left: 40%;
    top: 20%;
    z-index: 1000;
    height: 38px;
    width: 31px;
}
 
.busy-holder {
  background: transparent;
  width: 100%;
  height: 100%;
}
